import { useEffect, useState } from "react";
import { WhmBox, WhmLink, WhmTypography } from "@securitize/reactjs-whm";
import useInvestorInfo from "../../hooks/useInvestorInfo";
import W9TaxForm from "./components/W9Form";
import W8BENTaxForm from "./components/W8BENForm";
import W8BENETaxForm from "./components/W8BENEForm";
import FormTypeSelector, {
  ISRLinkResolver,
} from "./components/FormTypeSelector";
import useTranslations from "../../hooks/useTranslations";
import { translationKeys } from "../../contexts/I18nContext";
import useMixPanel, { TrackingType } from "../../hooks/useMixPanel";
import { TaxFormType } from "../../types/documents.interface";

const TaxForm = () => {
  const {
    isLoading,
    hasUSTax,
    hasForeignTax,
    isEntity,
    userInfo,
    investorInfo,
  } = useInvestorInfo();
  const [currentForm, setCurrentForm] = useState<TaxFormType | null>(null);
  const { getTranslation: t } = useTranslations();
  const track = useMixPanel();

  useEffect(() => {
    track(TrackingType.TA_PAYOUTS_OPENED_TAX_FORM_PROCESS);
  }, []);

  useEffect(() => {
    if (
      currentForm === null &&
      !isLoading &&
      (investorInfo.fullName !== "" || isEntity)
    ) {
      /**
       * W9 form is only for US persons and US entities
       * W8BEN form is only for non-US persons
       * W8BENE form is only for non-US entities
       */
      if (hasUSTax && !hasForeignTax) {
        track(TrackingType.TA_PAYOUTS_W9_FORWARD_PAGE_1);
        setCurrentForm(TaxFormType.W9);
      } else if (!isEntity && hasForeignTax && !hasUSTax) {
        track(TrackingType.TA_PAYOUTS_W8_FORWARD_PAGE_1);
        setCurrentForm(TaxFormType.W8BEN);
      } else if (isEntity && hasForeignTax && !hasUSTax) {
        track(TrackingType.TA_PAYOUTS_W8BEN_E_FORWARD_PAGE_1);
        setCurrentForm(TaxFormType.W8BENE);
      }
    }
  }, [hasUSTax, hasForeignTax, isEntity, investorInfo, isLoading]);

  const showFormSelector =
    hasUSTax && hasForeignTax && currentForm !== TaxFormType.W8BENE;

  return (
    <WhmBox component="section">
      <WhmBox
        sx={{
          padding: 4,
        }}
      >
        {showFormSelector ? (
          <WhmTypography variant="h4">
            {t(translationKeys.FORM_HEADER_TITLE)}
          </WhmTypography>
        ) : (
          <WhmTypography variant="h4">
            {t(translationKeys.FORM_HEADER_TITLE_DOCUMENT, {
              document: currentForm ?? "",
            })}
          </WhmTypography>
        )}
        {showFormSelector ? (
          <WhmTypography variant="body2">
            {t(translationKeys.FORM_HEADER_SUBTITLE)}
          </WhmTypography>
        ) : (
          <WhmTypography variant="body2">
            {t(translationKeys.FORM_HEADER_SUBTITLE_DOCUMENT, {
              document: currentForm ?? "",
            })}{" "}
            <WhmLink
              href={ISRLinkResolver[TaxFormType.W8BENE]}
              underline="none"
              variant="body2"
              rel="noopener noreferrer"
              sx={{ textDecoration: "underline" }}
            >
              {t(translationKeys.FORM_HEADER_SUBTITLE_DOCUMENT_LINK)}
            </WhmLink>
          </WhmTypography>
        )}
      </WhmBox>
      {showFormSelector && (
        <FormTypeSelector
          currentForm={currentForm}
          setCurrentForm={setCurrentForm}
          hasUSTax={hasUSTax}
          hasForeignTax={hasForeignTax}
        />
      )}
      {currentForm === TaxFormType.W9 && (
        <W9TaxForm
          userInfo={userInfo}
          investorInfo={investorInfo}
          isLoading={isLoading}
        />
      )}
      {currentForm === TaxFormType.W8BEN && (
        <W8BENTaxForm
          userInfo={userInfo}
          investorInfo={investorInfo}
          isLoading={isLoading}
        />
      )}
      {currentForm === TaxFormType.W8BENE && (
        <W8BENETaxForm
          userInfo={userInfo}
          investorInfo={investorInfo}
          isLoading={isLoading}
        />
      )}
    </WhmBox>
  );
};

export default TaxForm;

import { ISR, SHARED } from "@securitize/domain";
import { useEffect, useState } from "react";
import { getInvestorInformation } from "../data/rest/investorInfo";
import {
  InvestorInfo,
  InvestorInfoTax,
} from "../types/investorInformation.interface";
import useUserInfo from "./useUserInfo";
import { KycStatus } from "../types/UserInfo";

function useInvestorInfo() {
  const user = useUserInfo();
  const [investorInfo, setInvestorInfo] = useState<InvestorInfo | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user && investorInfo === undefined) {
      void getInvestorInformation().then((data) => {
        setInvestorInfo(data ?? null);
      });
      setLoading(false);
    }
  }, [user, investorInfo, setInvestorInfo]);

  const isEntity = user?.investorType === ISR.InvestorTypes.ENTITY;
  const verificationStatus = user.verificationStatus;

  if (
    [KycStatus.NOT_VERIFIED, KycStatus.REJECTED].includes(verificationStatus) ||
    !investorInfo
  ) {
    const initialInvestorInfo = {
      fullName: "",
      birthday: {
        date: "",
        city: "",
        country: "",
        state: "",
      },
      address: {
        street: "",
        houseNumber: "",
        entrance: "",
        city: "",
        countryCode: "",
        state: "",
        zip: "",
      },
      tax: [],
    };
    return {
      hasUSTax: false,
      hasForeignTax: false,
      isEntity,
      investorInfo: initialInvestorInfo,
      userInfo: user,
    };
  } else {
    const filteredInvestorInfo = { ...investorInfo };
    const hasUSCountryCode =
      SHARED.isUsTerritory(user?.countryCode ?? "") ||
      SHARED.isUsTerritory(investorInfo?.address?.countryCode ?? "");

    const hasUSTax =
      investorInfo.tax.filter(
        (tax: InvestorInfoTax) =>
          tax.taxCountryCode && SHARED.isUsTerritory(tax.taxCountryCode),
      ).length > 0 || hasUSCountryCode;

    const hasForeignTax =
      investorInfo.tax.filter(
        (tax: InvestorInfoTax) =>
          !tax.taxCountryCode || !SHARED.isUsTerritory(tax.taxCountryCode),
      ).length > 0 ||
      (investorInfo.address?.countryCode && !hasUSCountryCode);

    return {
      isLoading: loading,
      hasUSTax,
      hasForeignTax,
      isEntity,
      investorInfo: filteredInvestorInfo,
      userInfo: user,
    };
  }
}

export default useInvestorInfo;

import { SHARED } from "@securitize/domain";
import { InvestorInfo } from "../types/investorInformation.interface";
import { TaxIdType } from "../types/documents.interface";

export const getInvestorTaxInfo = (
  investorInfo: InvestorInfo,
  considerOnly?: "US" | "nonUS",
) => {
  const [taxObject] = investorInfo.tax.filter((i) => {
    if (considerOnly === "US") {
      return i.taxCountryCode && SHARED.isUsTerritory(i.taxCountryCode);
    }
    if (considerOnly === "nonUS") {
      return i.taxCountryCode && !SHARED.isUsTerritory(i.taxCountryCode);
    }
    return true;
  });
  const hasUSTax = SHARED.isUsTerritory(taxObject?.taxCountryCode ?? "");

  return {
    taxCountry: taxObject?.taxCountryCode ?? "",
    taxPayerIdType: hasUSTax ? TaxIdType.SSN : TaxIdType.FOREIGN,
    taxPayerIdNumber: taxObject?.taxId ?? "",
    foreignTaxPayerId: taxObject?.taxId ?? "",
  };
};

import { useState } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import {
  WhmStack,
  WhmFormControl,
  WhmButton,
  useWhmSnackbar,
} from "@securitize/reactjs-whm";
import FormBlock from "./FormBlock";
import { W9FormType } from "./FormType";
import {
  addressSchema,
  fullNameSchema,
  nameConfirmationSchema,
  RegionSchema,
  taxPayerSchema,
} from "./FormValidationSchemas";
import W9TaxInformationInputs from "./Inputs/W9TaxInformationInputs";
import ExemptionsInputs from "./Inputs/ExemptionsInputs";
import CertificationInputs from "./Inputs/CertificationInputs";
import PersonalInformationInputs from "./Inputs/PersonalInformationInputs";
import AddressInputs from "./Inputs/AddressInputs";
import { InvestorInfo } from "../../../types/investorInformation.interface";
import { KycStatus, UserInfo } from "../../../types/UserInfo";
import FormConfirmationModal from "./FormConfirmationModal";
import { postW9TaxForm } from "../../../data/rest/taxForms";
import { InvestorType, TaxFormType } from "../../../types/documents.interface";
import useTranslations from "../../../hooks/useTranslations";
import { translationKeys } from "../../../contexts/I18nContext";
import useMixPanel, { TrackingType } from "../../../hooks/useMixPanel";
import { getInvestorTaxInfo } from "../../../helpers/investor.utils";
import W9FormSkeleton from "./W9Form.skeleton";

const validationSchema = yup.object({
  ...addressSchema({
    address1: "address1",
    address2: "address2",
    locality: "city",
    postalCode: "postalCode",
  }),
  ...RegionSchema({
    region: "region",
  }),
  ...fullNameSchema({
    name: "formInvestorName",
    businessName: "businessName",
  }),
  ...taxPayerSchema({
    classification: "formClassification",
    identifier: "taxPayerIdType",
    exemptPayeeCodes: "exemptPayeeCodes",
    exemptFATCAReportingCodes: "exemptFATCAReportingCodes",
    taxPayerIdNumber: "taxPayerIdNumber",
  }),
  ...nameConfirmationSchema({
    nameConfirmation: "nameConfirmation",
    agree: "agree",
  }),
});

type Props = {
  isLoading?: boolean;
  userInfo: UserInfo;
  investorInfo: InvestorInfo;
};

const W9TaxForm = ({ isLoading, userInfo, investorInfo }: Props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formData, setFormData] = useState<W9FormType | undefined>();
  const snackbar = useWhmSnackbar();
  const track = useMixPanel();
  const { getTranslation: t } = useTranslations();
  const navigate = useNavigate();

  const onSubmit = async (values: W9FormType) => {
    track(TrackingType.TA_PAYOUTS_W9_SUBMIT);
    await postW9TaxForm(
      values,
      ![KycStatus.NOT_VERIFIED, KycStatus.REJECTED].includes(
        userInfo.verificationStatus,
      ),
    );
    snackbar.show(
      t(translationKeys.FORM_FEEDBACK_SUCCESS_DOCUMENT, {
        document: TaxFormType.W9,
      }),
      {
        severity: "success",
      },
    );
    navigate("/tax-center");
  };

  if (isLoading) return <W9FormSkeleton />;

  const isCheckedAndSigned = (values: W9FormType) =>
    Boolean(values.agree) && Boolean(values.nameConfirmation);
  const tax = getInvestorTaxInfo(investorInfo, "US");

  const fullName =
    userInfo.investorType === InvestorType.ENTITY
      ? userInfo.entityName
      : userInfo.fullName || investorInfo.fullName;

  return (
    <div>
      <Formik
        initialValues={{
          nameConfirmation: fullName,
          formInvestorName: fullName,
          businessName:
            userInfo.investorType === InvestorType.INDIVIDUAL
              ? ""
              : userInfo.entityName,
          address1: `${investorInfo?.address?.street} ${investorInfo?.address?.houseNumber}`,
          address2: investorInfo?.address?.entrance || "",
          city: investorInfo?.address?.city || "",
          country: investorInfo?.address?.countryCode || "",
          region: investorInfo?.address?.state || "",
          postalCode: investorInfo?.address?.zip || "",
          formClassification:
            userInfo.investorType === InvestorType.INDIVIDUAL
              ? InvestorType.INDIVIDUAL
              : "",
          taxPayerIdType: tax.taxPayerIdType,
          taxPayerIdNumber: tax.taxPayerIdNumber,
          taxCountry: tax.taxCountry,
          exemptPayeeCodes: "",
          exemptFATCAReportingCodes: "",
          formDate: moment().format("YYYY-MM-DD").toString(),
          agree: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setShowConfirmationModal(true);
          setFormData(values as W9FormType);
        }}
      >
        {({ isValid, values, submitForm }) => (
          <WhmFormControl fullWidth>
            <WhmStack spacing={6}>
              <FormBlock
                title={t(translationKeys.FORM_SECTION_PERSONAL_TITLE)}
                description={t(translationKeys.FORM_SECTION_PERSONAL_SUBTITLE)}
              >
                <PersonalInformationInputs disabled />
              </FormBlock>
              <FormBlock
                title={t(translationKeys.FORM_W9_SECTION_ADDRESS_TITLE)}
                description={t(
                  translationKeys.FORM_W9_SECTION_ADDRESS_SUBTITLE,
                )}
              >
                <AddressInputs disabled />
              </FormBlock>
              <FormBlock
                title={t(translationKeys.FORM_SECTION_TAX_INFORMATION_TITLE)}
                description={t(
                  translationKeys.FORM_SECTION_TAX_INFORMATION_SUBTITLE,
                )}
              >
                <W9TaxInformationInputs
                  investorType={userInfo.investorType}
                  disabled
                />
              </FormBlock>
              <FormBlock
                title={t(translationKeys.FORM_SECTION_EXEMPTIONS_TITLE)}
                description={t(
                  translationKeys.FORM_SECTION_EXEMPTIONS_SUBTITLE,
                )}
              >
                <ExemptionsInputs />
              </FormBlock>
              <FormBlock
                title={t(translationKeys.FORM_SECTION_CERTIFICATION_TITLE)}
                description={t(
                  translationKeys.FORM_SECTION_CERTIFICATION_SUBTITLE,
                )}
              >
                <CertificationInputs />
              </FormBlock>
              <WhmButton
                variant="contained"
                color="primary"
                sx={{ width: "fit-content" }}
                disabled={
                  !(isValid && isCheckedAndSigned(values as W9FormType))
                }
                onClick={submitForm}
              >
                {t(translationKeys.FORM_BUTTONS_SUBMIT)}
              </WhmButton>
            </WhmStack>
          </WhmFormControl>
        )}
      </Formik>
      {Boolean(formData) && showConfirmationModal && (
        <FormConfirmationModal
          open={showConfirmationModal}
          onConfirm={() => onSubmit(formData as W9FormType)}
          onClose={() => setShowConfirmationModal(false)}
          onCancel={() => setShowConfirmationModal(false)}
          onError={() => {
            setShowConfirmationModal(false);
            snackbar.show(t(translationKeys.FORM_FEEDBACK_ERROR), {
              severity: "error",
            });
          }}
        />
      )}
    </div>
  );
};

export default W9TaxForm;

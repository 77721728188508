import { InvestorType } from "./documents.interface";

export enum KycStatus {
  VERIFIED = "verified",
  PROCESSING = "processing",
  PENDING = "pending",
  MANUAL_REVIEW = "manual-review",
  UPDATES_REQUIRED = "updates-required",
  REJECTED = "rejected",
  EXPIRED = "expired",
  NOT_VERIFIED = "not-verified",
}

export interface UserInfo {
  countryCode: string;
  email: string;
  fullName: string;
  entityName: string;
  investorId: string;
  verificationStatus: KycStatus;
  investorType: InvestorType;
  accreditationStatus: string;
  suitabilityStatus: string;
  accountStatus: string;
  brokerDealerId?: number;
  language: string;
  state: string;
}
